import * as React from 'react'
import * as styles from '../../assets/styles.module.scss'
import Project from './Project'
import Timbrat from '../../assets/images/timbrat-app.png'
import MediosThink from '../../assets/images/medios-think.png'
import FMSBonds from '../../assets/images/fmsbonds.png'

interface Props {}
interface State {
    projects: any
}

export default class Grid extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        const projects = [
            {
                slug: 'media-monitoring-system',
                image: MediosThink,
                title: 'Think! Media Monitoring System',
                description: `Juvasoft partnered with Think! Mercadotecnia 
                to build from scratch a web application they've been using for the past 5 years.`,
                labels: ['web development', 'react', 'node']
            },
            {
                slug: 'fmbsbonds-from-legacy-to-modern',
                image: FMSBonds,
                title: 'FMSBonds: Contact Management',
                description: `We didn't want to just rebuild the product with a new tech stack,
                we went deeper into the business processes and proposed a more efficient way to do it.`,
                labels: ['business analysis', 'web development', 'react', 'node', 'electron']
            },
            {
                slug: 'timbrat-app-development',
                image: Timbrat,
                title: 'Timbrát',
                description: `Juvasoft partnered with Timbrát at a very 
                    early stage to fully design this product. From Idea to Market`,
                labels: ['branding', 'product development', 'web development', 'react']
            }
        ]

        this.state = { projects }
    }

    public render() {
        const { projects } = this.state

        return (
            <section className={styles.Grid}>
                {projects.map((item: any) => {
                    return (<Project key={item.id} item={item} />)
                })}
            </section>
        )
    }
}